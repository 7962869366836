/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { FiDollarSign, FiX } from 'react-icons/fi'
import { RiQuestionLine } from 'react-icons/ri'
import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  BtnVoltar,
  URContent,
  InfoContent,
  Line,
  InfoValuesBox,
} from './styles'
import { UserDetails } from '../../utils/interfaces'
import Input from '../../components/Input'
import getValidationErrors from '../../utils/getValidationErrors'
import Modal from '../../components/Modal'
import { FormSteps } from '../../components/FormSteps'

const ContributionP3: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora > 0
      ? userDetails.contribuicaoPatrocinadora
      : 0,
  )
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )
  const [ucsTit, setUcsTit] = useState(
    userDetails.ucsTitular > 0 ? userDetails.ucsTitular : 0,
  )
  const [ucsConj, setUcsConj] = useState(
    userDetails.ucsConjuge > 0 ? userDetails.ucsConjuge : 0,
  )
  const [flagEdit, setFlagEdit] = usePersistedState<'S' | 'N' | ''>(
    'flagEdit',
    'N',
  )

  const [UCS] = useState(19.07) // (15.15)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const valueTit = ucsTit * UCS
    const valueConj = ucsConj * UCS

    const valuePatroc = (valueTit + valueConj) * 0.5
    setVlrCtbBasica(valueTit)
    setVlrCtbSup(valueConj)
    setVlrCtbPatrocinadora(valuePatroc)
  }, [ucsTit, ucsConj, UCS])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          ucsTitular: Yup.number()
            .typeError('Campo obrigatório')
            .min(100, 'Mínimo de 100 UCS')
            .max(180, 'Máximo de 180 UCS')
            .required('Campo obrigatório'),
          ucsConjuge: Yup.number()
            .typeError('Campo obrigatório')
            .test(
              '',
              'Este campo deve ser 0 ou um valor entre 100 e 180.',
              () => ucsConj === 0 || ucsConj >= 100,
            )
            .max(180, 'Máximo de 180 UCS')
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserDetails({
          ...userDetails,
          ucsTitular: ucsTit,
          ucsConjuge: ucsConj,
          contribuicaoBasica: vlrCtbBasica,
          contribuicaoSuplementar: vlrCtbSup,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora,
        })

        if (flagEdit === 'S') {
          setFlagEdit('N')
          history.push('/resume')
        } else {
          // history.push('/investor-profile')
          history.push('/investor-quiz')
        }
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      flagEdit,
      history,
      setFlagEdit,
      setUserDetails,
      ucsConj,
      ucsTit,
      userDetails,
      vlrCtbBasica,
      vlrCtbPatrocinadora,
      vlrCtbSup,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            ucsTitular:
              userDetails.ucsTitular === undefined
                ? ''
                : userDetails.ucsTitular,
            ucsConjuge:
              userDetails.ucsConjuge === undefined
                ? ''
                : userDetails.ucsConjuge,
          }}
        >
          <Content>
            <FormSteps currentStep={0} />
            <div className="app_title">
              <strong>Contribuição mensal</strong>
              <RiQuestionLine onClick={() => setIsModalOpen(true)} />
            </div>
            <p>
              Tratamentos médicos, odontológicos etc. o participante também tem
              a possiblidade de contribuir para o cônjuge.
            </p>
            <InfoValuesBox color="green">
              <p>
                O valor da contribuição deve ser no mínimo
                <strong> 100 ucs</strong> e no máximo <strong>180 ucs</strong>
              </p>
              <p>
                Valor atual da UCS*:<strong> {formatValue(UCS)}</strong>
              </p>
              <small>* Este valor é atualizado anualmente</small>
            </InfoValuesBox>
            <URContent>
              <div className="UR-info">
                <p>Valor da contribuição</p>
              </div>
              <Input
                name="ucsTitular"
                placeholder="UCS Titular"
                type="number"
                max={180}
                min={100}
                value={ucsTit}
                onChange={e => setUcsTit(e.target.valueAsNumber)}
              />
              <Input
                name="ucsConjuge"
                placeholder="UCS Cônjuge"
                type="number"
                max={180}
                value={ucsConj}
                onChange={e => setUcsConj(e.target.valueAsNumber)}
              />
            </URContent>

            <Line />

            <InfoContent>
              <div>
                <p>Repique da empresa:</p>
                <strong>{formatValue(vlrCtbPatrocinadora)}</strong>
              </div>
              <div>
                <p>Desconto total:</p>
                <strong>{formatValue(vlrCtbBasica + vlrCtbSup)}</strong>
              </div>
            </InfoContent>
          </Content>
        </Form>

        <Button
          type="submit"
          color="green"
          onClick={handleClick}
          disabled={ucsTit < 100 && ucsTit > 180}
        >
          Próximo
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/welcome')}>
          &lt; Anterior
        </BtnVoltar>
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <FiX onClick={handleCloseModal} />
          <strong>SALÁRIO BASE</strong>
          <p>
            O salário informado corresponde ao último Salário Base de INSS,
            atualizado e registrado no sistema conforme dados informados pelo RH
            da Cargill. As contribuições mensais futuras serão calculadas com
            base no salário base de INSS atualizado (composto por todas as
            variáveis processadas na folha de pagamento, como férias, 13º, horas
            extras, entre outros).
          </p>
          <Line />
          <strong>UCS</strong>
          <p>
            No Plano Mais Vida, você pode efetuar Contribuições Básicas mensais
            que são determinadas pela quantidade de UCS’s que você escolhe
            (entre 100 e 180). Este índice é reajustado anualmente pelo mesmo
            índice de reajuste do plano médico da Cargill.
          </p>
          <Line />
          <strong>UCS Titular</strong>
          <p>
            No Plano Mais Vida, você pode efetuar Contribuições Básicas mensais
            correspondentes a um valor entre o mínimo de 100 UCS até o máximo de
            180 UCS.
            <br />A Cargill vai realizar contribuição como contrapartida de 50%
            do valor da sua contribuição mensal.
          </p>
          <Line />
          <strong>UCS Cônjuge</strong>
          <p>
            Além da sua Contribuição Básica de titular, você pode contribuir
            também para o cônjuge com os mesmos limites de UCS, entre o mínimo
            de 100 até o máximo de 180, com a contrapartida da empresa de 50%.
          </p>
        </Modal>
      </Container>
    </>
  )
}

export default ContributionP3
